import styles from './styles';

function Text({ text, className, url }) {
  return (
    <div className={className}>
      {url ? (
        <a className={className} href={url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        text
      )}
    </div>
  );
}

export default Text;