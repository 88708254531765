import {colors, fonts} from '../../Styles'
import { css } from 'emotion'

const headerText = css`
    height: 100%;
    // width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    // margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    color: white;
    background-color: ${colors.primary};
    // color: white;
    // background-color: #2991F0;
    font-size: 18px;
    // display: flex;
    // align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    font-family: ${fonts.primary};
`

const explanationText = css`
font-family: ${fonts.primary};
// height: 100%;
// width: 100%;
padding-left: 10%;
padding-right: 10%;
// font-weight: 600;
margin-bottom: 15px;
line-height: 24px;
/* identical to box height, or 150% */
letter-spacing: 0.15px;
font-size: 18px;
// display: flex;
// align-items: center;
`

const policyRow = css`
font-family: ${fonts.primary};
margin-top: 10px;
`

const styles = {
    headerText,
    explanationText,
    policyRow
}

export default styles




