import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import { fonts, colors } from '../../Styles'; // Assuming fonts/colors are defined here

import API from '../../API/API';

const containerStyle = css`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px; /* Default max width */
  padding: 40px 30px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 90%; /* Ensure the container doesn't take too much width on smaller screens */
    padding: 30px 20px; /* Reduced padding on medium screens */
  }

  @media (max-width: 480px) {
    max-width: 90%; /* Still 90% on small screens */
    padding: 20px 15px; /* Reduced padding for mobile */
  }
`;

const titleStyle = css`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  font-family: ${fonts.primary};
`;

const formStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const inputGroupStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
`;

const labelStyle = css`
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
  font-family: ${fonts.primary};
`;

const inputStyle = css`
  width: 100%;
  padding: 14px 16px; /* Added padding on the left and right */
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const errorStyle = css`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const submitButtonStyle = css`
  background-color: ${colors.primary};
  color: white;
  padding: 14px 16px; /* Same horizontal padding as the inputs */
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primaryDark};
  }

  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const mobileStyle = css`
  @media (max-width: 768px) {
    ${containerStyle} {
      max-width: 90%;
      padding: 30px 20px;
    }

    ${titleStyle} {
      font-size: 20px; /* Reduced title size on medium screens */
    }

    ${inputStyle} {
      font-size: 14px;
      padding: 12px;
    }

    ${submitButtonStyle} {
      font-size: 14px;
      padding: 12px;
    }
  }

  @media (max-width: 480px) {
    ${containerStyle} {
      padding: 20px 15px;
    }

    ${titleStyle} {
      font-size: 18px; /* Further reduced title size on small screens */
    }

    ${inputStyle} {
      font-size: 12px;
      padding: 10px;
    }

    ${submitButtonStyle} {
      font-size: 12px;
      padding: 10px;
    }
  }
`;

const ForgotPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetCode = urlParams.get('code');
    if (resetCode) {
      setCode(resetCode);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!code) {
      setError('No reset code provided.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (password.length < 12) {
      setError('Password must be at least 12 characters long.');
      return;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setError('Password must contain at least one uppercase letter.');
      return;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setError('Password must contain at least one lowercase letter.');
      return;
    }

    // Check for at least one number
    if (!/[0-9]/.test(password)) {
      setError('Password must contain at least one number.');
      return;
    }

    // Check for at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setError('Password must contain at least one special character.');
      return;
    }

    // Check for common passwords (basic example, can be expanded)
    const commonPasswords = [
      'password', '123456', '123456789', 'qwerty', 'abc123', 'password123',
      'letmein', 'welcome', 'iloveyou', 'admin', '12345', '1234', '1q2w3e4r',
      'qwerty123', 'password1', 'monkey', 'dragon', 'sunshine', 'trustno1',
      'qwertyuiop', '123321', '1qaz2wsx', '123123', 'welcome123', 'qazwsx',
      'letmein123', '123qwe', '123qaz', '111111', '123qwerty', 'abc123456',
      'chicken', 'lovely', 'iloveyou123', 'adobe123', 'soccer', 'baseball',
      'shadow', 'flower', 'password1', 'iloveyou1', 'dragon123', 'football',
      '123abc', 'iloveyou2', 'superman', 'qwert', 'batman', 'letmein1',
      '1234qwert', 'password1234', 'admin123', '123password', 'qwerty1234',
      '000000', 'welcome1', 'passw0rd', 'password1a', 'monkey123', 'password0',
      'princess', '123123123', 'p@ssw0rd', '1234abcd', '1password', 'qwerty1'
    ];
    if (commonPasswords.includes(password.toLowerCase())) {
      setError('Password is too common. Please choose a stronger password.');
      return;
    }

    setError(''); // Clear error if passwords match

    try {
      let result = await API.reset({
        newPassword: password,
        code: code,
      });

      // Assuming 'result' contains a status code field, you can check it like this:
      if (result.status === 200) {
        alert('Password has been successfully reset.');
      } else {
        alert(`Failed to reset password. Please try again.`);
      }
    } catch (error) {
      // If the request fails or there's an error in the API call
      alert(`Error resetting password: ${error.message}`);
    }
  };

  return (
    <div className={containerStyle}>
      <h1 className={titleStyle}>Reset Your Stream Password</h1>
      <form onSubmit={handleSubmit} className={formStyle}>
        <div className={inputGroupStyle}>
          <label htmlFor="password" className={labelStyle}>New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={inputStyle}
            required
            placeholder="Enter new password"
          />
        </div>
        <div className={inputGroupStyle}>
          <label htmlFor="confirmPassword" className={labelStyle}>Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={inputStyle}
            required
            placeholder="Re-enter your password"
          />
        </div>
        {error && <p className={errorStyle}>{error}</p>}
        <button type="submit" className={submitButtonStyle}>Submit</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
