import Container from '../../components/Container';
import React from 'react';
import Text from '../../components/Text';
import styles from './styles';

function Terms({ }) {

    const returnTextChunks = () => {
        return (
            <React.Fragment>
                <h1>Terms of Use</h1>
                <p><strong>Last Modified:</strong> December 23, 2024</p>

                <section>
                    <h2>Acceptance of the Terms of Use</h2>
                    <p>These terms of use are entered into by and between You and StreamFunds, Inc. ("Company," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use of <a href="https://www.getstreamapp.com">https://www.getstreamapp.com</a> and the Stream mobile application (collectively, "Website"), including any content, functionality, and services offered on or through the Website, whether as a guest or a registered user.</p>
                    <p>Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <a href="https://www.getstreamapp.com/legal/privacy">https://www.getstreamapp.com/legal/privacy</a>, and the End User License Agreement, found at <a href="https://www.getstreamapp.com/legal/end-user-license-agreement">https://www.getstreamapp.com/legal/end-user-license-agreement</a>, incorporated herein by reference. If you do not want to agree to these Terms of Use, Privacy Policy or the End User License Agreement, you must not access or use the Website.</p>
                    <p>This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.</p>
                </section>

                <section>
                    <h2>Changes to the Terms of Use</h2>
                    <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them.</p>
                    <p>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
                </section>

                <section>
                    <h2>Accessing the Website and Account Security</h2>
                    <p>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
                    <p>You are responsible for both:</p>
                    <ul>
                        <li>Making all arrangements necessary for you to have access to the Website.</li>
                        <li>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</li>
                    </ul>
                    <p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our <a href="https://www.getstreamapp.com/legal/privacy">Privacy Policy</a>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
                    <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
                    <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
                </section>

                <section>
                    <h2>Intellectual Property Rights</h2>
                    <p>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                    <p>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website, except as follows:</p>
                    <ul>
                        <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                        <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
                        <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
                        <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
                    </ul>
                    <p>You must not:</p>
                    <ul>
                        <li>Modify copies of any materials from this site.</li>
                        <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
                        <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
                    </ul>
                    <p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>
                    <p>If you wish to make any use of material on the Website other than that set out in this section, please address your request to: <a href="mailto:support@getstreamapp.com">support@getstreamapp.com</a>.</p>
                </section>

                <section>
                    <h2>Prohibited Uses</h2>
                    <p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</p>
                    <ul>
                        <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
                        <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
                        <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use.</li>
                        <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                        <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).</li>
                        <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</li>
                    </ul>
                    <p>Additionally, you agree not to:</p>
                    <ul>
                        <li>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real-time activities through the Website.</li>
                        <li>Use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
                        <li>Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
                        <li>Use any device, software or routine that interferes with the proper working of the Website.</li>
                        <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
                        <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</li>
                        <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                        <li>Otherwise attempt to interfere with the proper working of the Website.</li>
                    </ul>
                </section>

                <section>
                    <h2>User Contributions</h2>
                    <p>The Website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Website.</p>
                    <p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
                    <p>Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>
                </section>

                <section>
                    <h2>Disclaimer of Warranties</h2>
                    <p>YOU UNDERSTAND THAT WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY YOUR PARTICULAR REQUIREMENTS FOR ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT, AND FOR MAINTAINING A MEANS EXTERNAL TO OUR SITE FOR ANY RECONSTRUCTION OF ANY LOST DATA.</p>
                    <p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE.</p>
                </section>

                <section>
                    <h2>Limitation on Liability</h2>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO THE COMPANY FOR THE APPLICABLE PRODUCT OR SERVICE IN THE LAST 12 MONTHS OUT OF WHICH LIABILITY AROSE.</p>
                </section>

                <section>
                    <h2>Indemnification</h2>
                    <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</p>
                </section>

                <section>
                    <h2>Governing Law and Jurisdiction</h2>
                    <p>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).</p>
                </section>

                <section>
                    <h2>Arbitration</h2>
                    <p>At Company's sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying California law.</p>
                </section>

                <section>
                    <h2>Waiver and Severability</h2>
                    <p>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
                </section>

                <section>
                    <h2>Entire Agreement</h2>
                    <p>The Terms of Use, our Privacy Policy and our End User License Agreement constitute the sole and entire agreement between you and StreamFunds, Inc. with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.</p>
                </section>

                <section>
                    <h2>Your Comments and Concerns</h2>
                    <p>This website is operated by StreamFunds, Inc., 3703 Soaring Eagle Cove, Austin, Texas 78746.</p>
                    <p>Should you wish to delete your account and all associated data, you may do so within the Support section of the app. You may also email <a href="mailto:support@getstreamapp.com">support@getstreamapp.com</a> to submit a request to delete your account.</p>
                    <p>All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: <a href="mailto:support@getstreamapp.com">support@getstreamapp.com</a>.</p>
                </section>
            </React.Fragment>
        );
    };

    const returnForm = () => {
        return (
            <Container className={styles.background}>
{/*                 <Text className={styles.headerText} text={'Terms Of Use'} /> */}
                {returnTextChunks()}
            </Container>
        );
    };

    return (
        <Container>
            {returnForm()}
        </Container>
    );
}

export default Terms;