import React from 'react';
import Container from '../../components/Container';
import Text from '../../components/Text';
import styles from './styles';

function EndUserLicenseAgreement() {
    const returnTextChunks = () => {
        return (
            <React.Fragment>
                <div>
                    <h1>End User License Agreement</h1>
                    <p>
                        This End User License Agreement (this "Agreement"), is a binding agreement between StreamFunds, Inc. ("Stream") and the person or entity identified on the information submitted when creating an account as the licensee of the Software ("Licensee").
                    </p>
                    <p>
                        STREAM PROVIDES THE SOFTWARE AND SERVICES SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM.
                        BY CHECKING THE "ACCEPT" BOX YOU (A) ACCEPT THIS AGREEMENT AND AGREE THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT YOU ARE 18 YEARS OF AGE OR OLDER.
                        IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, STREAM WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO LICENSEE AND YOU MUST NOT DOWNLOAD THE SOFTWARE OR DOCUMENTATION.
                    </p>
                    <p>
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE'S ACCEPTANCE OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, NO LICENSE IS GRANTED
                        (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER THIS AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY SOFTWARE THAT LICENSEE
                        DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED COPY OF STREAM'S SOFTWARE.
                    </p>
                    <h2>1. Definitions</h2>
                    <p>For purposes of this Agreement, the following terms have the following meanings:</p>
                    <ul>
                        <li><strong>"Documentation"</strong>: Stream's end user documentation relating to the Software available at https://www.getstreamapp.com/ or available after downloading the app, before or after creating an account.</li>
                        <li><strong>"Intellectual Property Rights"</strong>: Any and all registered and unregistered rights granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection, or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.</li>
                        <li><strong>"Fees"</strong>: The fees, including all taxes thereon, paid or required to be paid by Licensee for the license and Services granted under this Agreement.</li>
                        <li><strong>"Person"</strong>: An individual, corporation, partnership, joint venture, limited liability company, governmental authority, unincorporated organization, trust, association, or other entity.</li>
                        <li><strong>"Services"</strong>: The services provided by the Software, which are made available either before or after the Licensee registers an account, or at the Check-Out Page.</li>
                        <li><strong>"Software"</strong>: The product available for download in the app store or https://www.getstreamapp.com/, including any Updates provided to Licensee pursuant to this Agreement.</li>
                        <li><strong>"Third Party"</strong>: Any Person other than Licensee or Stream.</li>
                        <li><strong>"Updates"</strong>: Any updates, bug fixes, patches, or other error corrections to the Software that Stream generally makes available free of charge to all licensees of the Software.</li>
                    </ul>
                    <h2>2. License Grant and Scope</h2>
                    <p>
                        Subject to and conditioned upon Licensee’s compliance with all terms and conditions set forth in this Agreement, Stream hereby grants Licensee a non-exclusive, non-sublicensable,
                        non-transferable license, during the Term, to download, install, and use the Software.
                    </p>
                    <p>All copies of the Software made by the Licensee:</p>
                    <ul>
                        <li>will be the exclusive property of the Stream;</li>
                        <li>will be subject to the terms and conditions of this Agreement; and</li>
                        <li>must include all trademark, copyright, patent, and other Intellectual Property Rights notices.</li>
                    </ul>
                    <h2>3. Provision of Services</h2>
                    <p>Stream may:</p>
                    <ul>
                        <li>Suspend or remove Licensee's account for any or no reason; and</li>
                        <li>Deny Services for any or no reason.</li>
                    </ul>
                    <h2>4. Use Restrictions</h2>
                    <p>Licensee shall not directly or indirectly:</p>
                    <ul>
                        <li>use (including make any copies of) the Software or Documentation beyond the scope of the license granted under Section 2;</li>
                        <li>provide any other Person, including any subcontractor, independent contractor, affiliate, or service provider of Licensee, with access to or use of the Software or Documentation;</li>
                        <li>modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Software or Documentation or any part thereof;</li>
                        <li>combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;</li>
                        <li>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;</li>
                        <li>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices provided on or with the Software or Documentation, including any copy thereof;</li>
                        <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Software, or any features or functionality of the Software, to any Third Party for any reason;</li>
                        <li>use the Software or Documentation in violation of any law, regulation, or rule;</li>
                        <li>use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service, or any other purpose that is to the Stream's commercial disadvantage.</li>
                    </ul>
                    <h2>5. Responsibility for Use of Software</h2>
                    <p>
                        Licensee is responsible and liable for all uses of the Software, Services, and Documentation through access thereto provided by Licensee, directly or indirectly.
                    </p>
                    <h2>6. Collection and Use of Information</h2>
                    <p>
                        Licensee acknowledges that Stream may, directly or indirectly through the services of Third Parties, collect and store information regarding use of the Software and about equipment on which the Software is installed or through which it otherwise is accessed and used, through:
                    </p>
                    <ul>
                        <li>the provision of maintenance and support services; and</li>
                        <li>security measures included in the Software.</li>
                    </ul>
                    <p>
                        Licensee agrees that Stream may use such information for any purpose related to any use of the Software by Licensee or on Licensee's equipment, including but not limited to:
                    </p>
                    <ul>
                        <li>improving the performance of the Software or developing Updates; and</li>
                        <li>verifying Licensee's compliance with the terms of this Agreement and enforcing Stream's rights, including all Intellectual Property Rights in and to the Software.</li>
                    </ul>
                    <h2>7. Intellectual Property Rights</h2>
                    <p>
                        Licensee acknowledges and agrees that the Software and Documentation are provided under license, and not sold, to Licensee. Licensee does not acquire any ownership interest in the Software or Documentation under this Agreement, or any other rights thereto, other than to use the same in accordance with the license granted and subject to all terms, conditions, and restrictions under this Agreement.
                    </p>
                    <p>
                        Stream reserves and shall retain its entire right, title, and interest in and to the Software and all Intellectual Property Rights arising out of or relating to the Software, except as expressly granted to the Licensee in this Agreement. Licensee shall safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse, or unauthorized access.
                    </p>
                    <h2>8. Payments</h2>
                    <p>
                        All Fees are payable in advance in the manner set forth on the Check-Out Page for paid Services and are non-refundable.
                    </p>
                    <p>
                        By using the Software and/or Services, you authorize Stream to bill your bank account for the sum of all unpaid withdrawals that you have made during the previous pay period, as well as third-party processing fees.
                        Stream is allowed to reject Licensee for any reason and is not required to state or provide a reason for such rejections. Stream is also allowed to terminate any account for any reason, and likewise is not required to provide reason or justification.
                    </p>
                    <p>
                        Licensee represents that any bank account connected is yours and yours alone, and that the income information shared represents, to the best of your knowledge, the combined data of all your income sources.
                    </p>
                    <p>
                        Licensee accepts that, should Stream reach out for clarification on any income sources, Licensee shall provide accurate information reflective of your income source(s).
                    </p>
                    <p>
                        By using the Software and/or Services, Licensee agrees that they shall be responsible for any third-party bank fees. This includes fees by Licensee's bank or any third parties, including Plaid Inc. or Pinwheel, as stated at the Check-Out Page.
                        Licensee also agrees that in any month the Licensee uses Stream to make one or more withdrawals, for each withdrawal, Licensee authorizes Stream to withdraw the associated fee from any of the Licensee's connected bank accounts or debit it from the next direct deposit payment.
                    </p>
                    <p>
                        Fees are due immediately upon repayment, and withdrawals must be paid within 21 days following each withdrawal. If the 21-day period elapses without payment, Stream reserves the right to withdraw funds from any bank account to which it has access, either immediately or through installments.
                    </p>

                    <h2>9. Term and Termination</h2>
                    <p>
                        This Agreement and the license granted hereunder shall remain in effect so long as you are accessing our Software and/or Services (the "Term").
                    </p>
                    <p>
                        Licensee may terminate this Agreement by ceasing to use the Services provided that you have no amounts owed for our Services.
                    </p>
                    <p>
                        Stream may terminate this Agreement, effective upon written notice to Licensee, if Licensee breaches this Agreement and such breach:
                    </p>
                    <ul>
                        <li>is incapable of cure; or</li>
                        <li>being capable of cure, remains uncured 30 days after Stream provides written notice thereof.</li>
                    </ul>
                    <p>
                        Stream may terminate this Agreement, effective immediately, if Licensee files, or has filed against it, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law,
                        makes or seeks to make a general assignment for the benefit of its creditors, or applies for, or consents to, the appointment of a trustee, receiver, or custodian for a substantial part of its property.
                    </p>
                    <p>
                        Upon expiration or earlier termination of this Agreement, the license granted hereunder shall also terminate, and Licensee shall cease using and destroy all copies of the Software and Documentation.
                        No expiration or termination shall affect Licensee's obligation to pay all Fees that may have become due before such expiration or termination or entitle Licensee to any refund.
                    </p>

                    <h2>10. Warranty Disclaimer</h2>
                    <p>
                        THE SOFTWARE, SERVICES, AND DOCUMENTATION ARE PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
                        STREAM, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE STREAMS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
                        STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND DOCUMENTATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT,
                        AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, STREAM PROVIDES NO WARRANTY OR UNDERTAKING,
                        AND MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE WILL MEET THE LICENSEE'S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
                        APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                    </p>
                    <p>
                        AS A THIRD PARTY RECIPIENT OF INCOME DATA, STREAM MAKES NO CLAIM AS TO THE ACCURACY OF ANY LICENSEE INCOME DATA.
                    </p>

                    <h2>11. Limitation of Liability</h2>
                    <p>
                        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:
                    </p>
                    <ul>
                        <li>
                            IN NO EVENT WILL STREAM OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE STREAMS OR SERVICE PROVIDERS, BE LIABLE TO LICENSEE OR ANY THIRD PARTY FOR ANY USE, INTERRUPTION, DELAY, OR
                            INABILITY TO USE THE SOFTWARE; LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF SERVICES, BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA; LOSS RESULTING FROM SYSTEM OR
                            SYSTEM SERVICE FAILURE, MALFUNCTION, OR SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT INFORMATION; FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION; SYSTEM
                            INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION; OR BREACHES IN SYSTEM SECURITY; SOFTWARE BUGS; OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                            OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, SERVICES, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH
                            DAMAGES WERE FORESEEABLE AND WHETHER OR NOT STREAM WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </li>
                        <li>
                            IN NO EVENT WILL STREAM'S AND ITS AFFILIATES', INCLUDING ANY OF ITS OR THEIR RESPECTIVE STREAMS' AND SERVICE PROVIDERS', COLLECTIVE AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH
                            THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE TOTAL
                            AMOUNT PAID TO STREAM PURSUANT TO THIS AGREEMENT FOR (i) THE SERVICES OR (ii) UP TO TWELVE (12) MONTHS OF THE SPECIFIC SERVICES, THAT IS OR ARE THE SUBJECT OF THE CLAIM.
                        </li>
                    </ul>
                    <p>
                        THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY EVEN IF THE LICENSEE'S REMEDIES UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
                    </p>

                    <h2>12. Export Regulation</h2>
                    <p>
                        The Software may be subject to US export control laws, including the Export Control Reform Act and its associated regulations. Licensee shall not, directly or indirectly, export, re-export,
                        or release the Software to, or make the Software accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. Licensee shall
                        comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval),
                        prior to exporting, re-exporting, releasing, or otherwise making the Software available outside the US.
                    </p>

                    <h2>13. Miscellaneous</h2>
                    <ul>
                        <li>All matters arising out of or relating to this Agreement shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule.</li>
                        <li>
                            Any legal suit, action, or proceeding arising out of or relating to this Agreement or the transactions contemplated hereby shall be instituted in the federal courts of the United States of America or the courts of the State of Delaware, provided that Stream can bring any action in any venue where the Licensee has assets.
                        </li>
                        <li>
                            Stream shall not be liable for any failure or delay in performing its obligations under this Agreement if such failure or delay is caused by circumstances beyond its reasonable control, including acts of God, government actions, or other force majeure events.
                        </li>
                    </ul>

                    <h2>14. Severability</h2>
                    <p>
                        If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
                    </p>

                    <h2>15. Entire Agreement</h2>
                    <p>
                        This Agreement, together with the Check-Out Page, constitutes the sole and entire agreement between Licensee and Stream with respect to the subject matter contained herein and supersedes all prior agreements, representations, and warranties, both written and oral.
                    </p>

                    <h2>16. Assignment</h2>
                    <p>
                        Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations, under this Agreement without the prior written consent of Stream.
                    </p>

                    <h2>17. Waiver</h2>
                    <p>
                        No waiver by any party of any of the provisions of this Agreement shall be effective unless explicitly set forth in writing.
                    </p>

                    <h2>18. Counterparts</h2>
                    <p>
                        This Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same Agreement.
                    </p>

                    <h2>19. Headings</h2>
                    <p>
                        The headings in this Agreement are for reference only and do not affect the interpretation of this Agreement.
                    </p>

                    <h2>20. Construction</h2>
                    <p>
                        This Agreement shall be construed without regard to any presumption or rule requiring construction or interpretation against the party drafting the document.
                    </p>

                    <h2>21. Notices</h2>
                    <p>
                        All notices, requests, and other communications under this Agreement must be in writing and shall be deemed properly delivered if sent via certified mail or email with confirmation of receipt.
                    </p>

                    <h2>22. Governing Language</h2>
                    <p>
                        This Agreement is governed by and written in English, which shall prevail in all respects.
                    </p>

                    <h2>23. Amendments</h2>
                    <p>
                        This Agreement may only be amended, modified, or supplemented by an agreement in writing signed by both parties.
                    </p>

                </div>
            </React.Fragment>
        );
    };

    const returnForm = () => {
        return (
            <React.Fragment>
                {/* <Text className={styles.headerText} text={''} /> */}
                {returnTextChunks()}
            </React.Fragment>
        );
    };

    return (
        <Container className={styles.background}>
            {returnForm()}
        </Container>
    );
}

export default EndUserLicenseAgreement;