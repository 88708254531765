import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'

function TermsAndPolicies({ }) {

    const sections = [
        {text: "At Stream, we're committed to empowering you with financial flexibility and peace of mind by giving you easy access to your earned wages before payday. Our platform helps you take control of your cash flow, eliminate financial stress, and manage your expenses in real time."},
        {text: "This page outlines our terms of use, privacy policies, and your rights and responsibilities as a user of our services. We encourage you to carefully review these terms to understand how we protect your information and provide a secure, seamless experience with Stream."},
    ];

    const policyLinks = [
        { name: 'Terms of Use', url: '/legal/terms' },
        { name: 'Privacy Policy', url: '/legal/privacy' },
        { name: 'End User License Agreement', url: '/legal/end-user-license-agreement' },
    ];

    const returnTextChunks = () => {
        return (
            <React.Fragment>
                {sections.map((section, index) => (
                    <Text key={index} className={styles.explanationText} text={section.text} />
                ))}
            </React.Fragment>
        );
    }

    const renderPolicyLinks = () => {
        return (
            <React.Fragment>
                {policyLinks.map((policy, index) => (
                    <div key={index} className={styles.policyRow}>
                        <a className={styles.explanationText} href={policy.url} target="_blank" rel="noopener noreferrer">
                            {policy.name}
                        </a>
                    </div>
                ))}
            </React.Fragment>
        );
    };
    

    const returnForm = () => {
        return (
            <React.Fragment>
                <Text className={styles.headerText} text={'Terms & Policies'} />
                {returnTextChunks()}
                {renderPolicyLinks()}
            </React.Fragment>
        );
    }

    return (
        <Container>
            {returnForm()}
        </Container>
    );
}

export default TermsAndPolicies;
